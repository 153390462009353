import moment from "moment";

const langs = {
  kz: "kk",
  qz: "kk",
  ru: "ru",
  en: "en",
};

moment.locale(langs[document.documentElement.lang]);

export default moment;
