import React from "react";

const Modal = ({ onButtonClick, title, description, buttonText }) => (
  <div className="modal-wrapper">
    <div className="modal-overlay">
      <div className="modal">
        <p className="size-l">
          {title}
        </p>
        <p>
          {description}
        </p>
        <button
          type="button"
          className="button"
          onClick={onButtonClick}
        >
          {buttonText}
        </button>
      </div>
    </div>
  </div>
);

export default Modal;
