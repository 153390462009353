import "core-js/stable";
import "regenerator-runtime/runtime";

import humps from "humps";

import Turbolinks from "turbolinks";
import Rails from "@rails/ujs";

import React from "react";
import ReactDOM from "react-dom";

import "src/my/js/ui";
import "src/my/js/timestamps.js";
import "src/my/js/imask";
import "src/my/js/modal";
import "src/my/js/yandex_money";
import "src/my/js/qiwi_card";
import "src/my/js/timepicker";
import "src/my/js/countdown";
import "src/my/js/reset_notifications";
import "src/my/js/highlightable";
import "src/my/js/ton";
import "src/my/js/copy_to_clipboard";

import "src/my/scss/main.scss";
import "src/my/images";

import apps from "../src/my/js/apps";

Rails.start();
Turbolinks.start();

document.addEventListener("turbolinks:load", () => {
  // Render components
  const tags = document.querySelectorAll("[data-handler=\"react\"]");
  [].forEach.call(tags, (tag) => {
    const data = humps.camelizeKeys(JSON.parse(tag.getAttribute("data-props")));
    const componentName = tag.getAttribute("id");
    const Component = apps[componentName];
    // eslint-disable-next-line react/jsx-filename-extension
    ReactDOM.render(<Component {...data} />, tag);
  });
});


