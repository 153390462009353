import React from "react";

class VerificationStatus extends React.Component {
  render() {
    const { photos, threshold } = this.props;
    const verifiedPhotos = photos.filter(photo => photo.verifiedAt);
    const klassName = verifiedPhotos.length >= threshold ? "verified" : "not-verified";
    return (
      verifiedPhotos.length > 0 && (
        <div className={`photomanager-verification-status photomanager-verification-status--${klassName}`} >
          <p>Кол-во проверенных фото {verifiedPhotos.length}.</p>
          {(verifiedPhotos.length < threshold && <p>🔴 Для получения статуса "Проверенная анкета" нужно еще {threshold - verifiedPhotos.length} проверенное фото.</p>)}
        </div>
      )
    );
  }
}

export default VerificationStatus;