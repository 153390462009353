import moment from "./utils/moment.js";

document.addEventListener("turbolinks:load", () => {
  const result = (time, format) => {
    switch (format) {
      case "human":
        return moment(time).calendar();
      case "simple":
        return moment(time).format("DD.MM.YY (dddd) HH:mm");
      case "date":
        return moment(time).format("DD.MM.YY");
      case "day_of_week":
        return moment(time).format("dddd");
      case "date_with_time":
        return moment(time).format("DD.MM.YY HH:mm");
      default:
        return moment(time).format("dddd, MMMM Do YYYY, h:mm:ss a");
    }
  };

  const timestamps = document.querySelectorAll("[data-time]");
  [].forEach.call(timestamps, (timestamp) => {
    const { time, format } = timestamp.dataset;
    timestamp.innerHTML = result(time, format); // eslint-disable-line no-param-reassign
  });
});
