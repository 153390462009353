import React from "react";

const Photo = ({ photo }) => (
  <picture className="image is-256x256">
    { photo.verifiedAt && <div className="slider-photo-verified">Фото проверено</div>}
    <source srcSet={photo.image.mWebp.url} type="image/webp" />
    <source srcSet={photo.image.m.url} type="image/jpeg" />
    <img src={photo.image.m.url} />
  </picture>
);

export default Photo;
