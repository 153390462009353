document.addEventListener("turbolinks:load", () => {
  const btns = document.querySelectorAll("[data-hide-and-open]");

  [].forEach.call(btns, (btn) => {
    btn.addEventListener("click", (e) => {
      e.preventDefault();
      const elem = document.getElementById(btn.getAttribute("data-hide-and-open"));
      btn.classList.add("hidden");
      elem.classList.remove("hidden");
    });
  });

  const focusBtns = document.querySelectorAll("[data-focus]");

  [].forEach.call(focusBtns, (btn) => {
    btn.addEventListener("click", (e) => {
      e.preventDefault();
      const elem = document.getElementById(btn.getAttribute("data-focus"));
      elem.focus();
    });
  });

  // Show after ms data-show-after-ms

  const showAfterMs = document.querySelectorAll("[data-show-after-ms]");
  [].forEach.call(showAfterMs, (elem) => {
    // Add hidden class
    elem.classList.add("hidden");

    setTimeout(() => {
      elem.classList.remove("hidden");
    }, elem.getAttribute("data-show-after-ms"));
  });
});
