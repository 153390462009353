import IMask from "imask";

document.addEventListener("turbolinks:load", () => {
  const phones = document.querySelectorAll("[data-phone]");
  const masks = [
    {
      mask: '+{7} (000) 000-00-00',
      startsWith: ['7', '8'],
      lazy: true,
      country: 'KZ'
    },
    {
      mask: '+{996} (000) 000-000',
      startsWith: '9',
      lazy: true,
      country: 'KG'
    },
  ];

  const maskOptions = {
    mask: masks,
    dispatch: (appended, dynamicMasked) => {
      const potentialValue = dynamicMasked.value + appended;
      
      const number = potentialValue.replace(/\D/g, '');
    
      // First, check for the masks based on the first character of the number.
      let matchingMask = dynamicMasked.compiledMasks.find(m => {
        if (Array.isArray(m.startsWith)) {
          return m.startsWith.includes(number[0]);
        } else {
          return m.startsWith === number[0];
        }
      });
    
      // If no mask is found based on the first character, then check for the '+'
      if (!matchingMask && potentialValue.startsWith('+')) {
        matchingMask = dynamicMasked.compiledMasks[0];
      }
    
      return matchingMask;
    }
  };

  [].forEach.call(phones, (phone) => {
    let mask = new IMask(phone, maskOptions);

    // Add input event listener
    addEventListener(phone, mask, maskOptions);
  });
});

function addEventListener(phone, mask, maskOptions) {
  phone.addEventListener('input', function() {
    console.log(phone.value);
    const numbers = this.value.replace(/\D/g,'');
    if (numbers[0] == 7 && numbers[1] == 8) {
      mask.destroy();  // Destroy the current mask
      this.value = '+7';  // Clear the field's value
      mask = new IMask(phone, maskOptions);  // Reinitialize the mask
    }

    if (numbers[0] == 8) {
      mask.destroy();  // Destroy the current mask
      this.value = '+7';  // Clear the field's value
      mask = new IMask(phone, maskOptions);  // Reinitialize the mask
    }
  });
};
