import React from "react";

import { SortableElement } from "react-sortable-hoc";

import DeleteIcon from "src/my/images/delete.png";

const Photo = SortableElement(({ id, image, position, aasmState, sortMode, handlePhotoDelete, verifiedAt }) => (
  <div className="photomanager-photo">
    <div className="__idx">
      {position + 1}
    </div>
    { !sortMode
      && (
        <button
          type="button"
          onClick={() => handlePhotoDelete(id)}
          className="__delete-button"
        >
          <img src={DeleteIcon} />
        </button>
      )
    }
    {/* If photo verifed show */}
    { verifiedAt
      && (
        <div className="photomanager-photo__verified">
          <span>Фото проверено</span>
        </div>
      )
    }
    <img
      width="100px"
      alt="ProfilePhoto"
      src={image.m.url}
    />
  </div>
));

export default Photo;
