import React from "react";
import PropTypes from "prop-types";

import uuid4 from "uuid/v4";
import humps from "humps";
import resizeImage from "resize-image";
import smartcrop from "smartcrop";
import axios from "axios";
import { arrayMove } from "react-sortable-hoc";

import Preloader from "./Preloader";
import Photos from "./Photos";
import Modal from "./Modal";
import VerificationStatus from "./VerificationStatus";

axios.interceptors.response.use(res => humps.camelizeKeys(res));

class PhotoManager extends React.Component {
  constructor(props) {
    const showSortHowto = localStorage.getItem("showSortHowto") === null || localStorage.getItem("showSortHowto") === true;
    super(props);
    this.fileFieldRef = React.createRef();
    this.state = { photos: props.photos, processors: [], sortMode: false, showSortHowto };
    this.handleFiles = this.handleFiles.bind(this);
    this.handleModalButtonClick = this.handleModalButtonClick.bind(this);
    this.uploadPhoto = this.uploadPhoto.bind(this);
    this.getPhotos = this.getPhotos.bind(this);
    this.toggleSortMode = this.toggleSortMode.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.submitSort = this.submitSort.bind(this);
    this.handlePhotoDelete = this.handlePhotoDelete.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { photos } = this.state;
    const element = document.getElementById("promote");
    const alerts = document.getElementById("alerts-block");

    if(photos.length === 0) {
      element.classList.add("hidden");
    } else {
      element.classList.remove("hidden");
      if (alerts) {
        alerts.classList.add("hidden");
      }
    }
  }

  onSortEnd({ oldIndex, newIndex }) {
    this.setState(prevState => ({
      photos: arrayMove(prevState.photos, oldIndex, newIndex),
    }), () => this.submitSort());
  }

  getPhotos() {
    axios.get(`/my/photos.json`).then(
      res => this.setState({ photos: res.data.photos }),
    );
  }

  handlePhotoDelete(id) {
    if (this.state.photos.find(photo => photo.id === id).verifiedAt) {
      if (window.confirm("Вы удаляете проверенную фотографию! Если кол-во проверенных фотографий будет меньше 3, то у анкеты не будет статуса \"Проверенная\".")) {
        this.deletePhoto(id);
      }
    }
    else {
      this.deletePhoto(id);
    }
  }

  deletePhoto(id) {
    const uuid = uuid4();
    this.setState(prevState => ({ processors: [...prevState.processors, uuid] }));

    // DELETING IS TOO FAST
    // We need to show preloader for user to make him sure
    // that everything is working properly and there was
    // some process...
    setTimeout(() => {
      axios.delete(`/my/photos/${id}.json`).then(
        res => this.setState(prevState => ({ photos: res.data.photos, processors: prevState.processors.filter(elem => elem !== uuid) }))
      );
    }, 500);
  }

  handleFiles(files) {
    const MIN_SIZE = 768;
    Array.from(files).forEach((file) => {
      const uuid = uuid4();
      this.setState(prevState => ({ processors: [...prevState.processors, uuid] }));
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const img = new Image();
        img.onload = () => {
          let width;
          let height;

          if (img.height > img.width) {
            width = MIN_SIZE;
            height = parseInt(img.height / img.width * MIN_SIZE, 10);
          } else {
            height = MIN_SIZE;
            width = parseInt(img.width / img.height * MIN_SIZE, 10);
          }

          const resizedImg64 = resizeImage.resize(img, width, height, resizeImage.JPEG);

          const resizedImg = new Image();
          resizedImg.onload = () => {
            smartcrop.crop(resizedImg, { width: MIN_SIZE, height: MIN_SIZE }).then((result) => {
              const { topCrop } = result;
              this.uploadPhoto({ image: resizedImg64, data: { x: topCrop.x, y: topCrop.y, width: topCrop.width, height: topCrop.width } }, uuid);
            });
          };
          resizedImg.src = resizedImg64;
        };
        img.src = reader.result;
      }, false);
      reader.readAsDataURL(file);
    });
  }

  handleModalButtonClick() {
    this.setState({ showSortHowto: false });
    localStorage.setItem("showSortHowto", false);
  }

  uploadPhoto(photoWithData, uuid) {
    axios.post(
      `/my/photos.json`,
      {
        photo: {
          data: photoWithData.data,
          image: photoWithData.image,
        },
      },
      { headers: { "Content-Type": "application/json" } }
    ).then(
      () => {
        this.setState(prevState => ({ processors: prevState.processors.filter(elem => elem !== uuid) }));
        this.getPhotos();
      },
    );
  }

  toggleSortMode() {
    this.setState(prevState => ({ sortMode: !prevState.sortMode }));
  }

  submitSort() {
    const { photos } = this.state;
    const positions = photos.map(photo => ({ id: photo.id }));
    axios.post(`/my/photos/sort.json`, { positions }, { headers: { "Content-Type": "application/json" } }).then(() => {});
  }

  render() {
    const {
      buttonAddPhotoText,
      buttonSortButtonText,
      buttonSortEndButtonText,
      noPhotosText,
      noPhotosImage,
      pendingLinks,
      verificationStatusThreshold,
      sortHelpTitle,
      sortHelpDescription,
      sortHelpCloseText,
    } = this.props;
    const { photos, processors, sortMode, showSortHowto } = this.state;
    return (
      <div className={`photomanager ${photos.length == 0 && "no-photos"}`}>
        {
          sortMode
          && showSortHowto
          && (
            <Modal
              title={sortHelpTitle}
              description={sortHelpDescription}
              buttonText={sortHelpCloseText}
              onButtonClick={this.handleModalButtonClick}
            />
          )
        }
        <VerificationStatus photos={photos} threshold={verificationStatusThreshold} />
        <Photos
          axis="xy"
          pressDelay={100}
          onSortEnd={this.onSortEnd}
          handlePhotoDelete={this.handlePhotoDelete}
          photos={photos}
          sortMode={sortMode}
          noPhotosText={noPhotosText}
          noPhotosImage={noPhotosImage}
          onNoPhotosImageClick={() => this.fileFieldRef.current.click()}
        />

        {processors.length === 0
          ? (
            <div className="text-center">
              <input
                multiple
                accept="image/*"
                type="file"
                className="hidden"
                ref={this.fileFieldRef}
                onChange={e => this.handleFiles(e.target.files)}
              />
              <div className={`__buttons ${photos.length > 1 && "multiple"}`}>
                <button
                  type="button"
                  className="button"
                  onClick={() => this.fileFieldRef.current.click()}
                >
                  {buttonAddPhotoText}
                </button>
                {
                  photos.length > 1 && (
                    <button
                      type="button"
                      className="button"
                      onClick={this.toggleSortMode}
                    >
                      {sortMode ? buttonSortEndButtonText : buttonSortButtonText}
                    </button>
                  )

                }
              </div>
            </div>
          )
          : <Preloader />
        }
      </div>
    );
  }
}

PhotoManager.propTypes = {
  buttonAddPhotoText: PropTypes.string.isRequired,
  buttonSortButtonText: PropTypes.string.isRequired,
  noPhotosText: PropTypes.string.isRequired,
  noPhotosImage: PropTypes.string.isRequired,
  sortHelpTitle: PropTypes.string.isRequired,
  sortHelpDescription: PropTypes.string.isRequired,
  sortHelpCloseText: PropTypes.string.isRequired,
  buttonSortEndButtonText: PropTypes.string.isRequired
};

export default PhotoManager;
