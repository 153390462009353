import React from "react";

const Preloader = () => (
  <div className="spinner-wrapper">
    <div className="spinner-container">
      <div className="spinner" />
    </div>
  </div>
);

export default Preloader;
