document.addEventListener("turbolinks:load", () => {
  const openBtns = document.querySelectorAll("[data-open]");
  const closeBtns = document.querySelectorAll("[data-close]");

  [].forEach.call(openBtns, (btn) => {
    btn.addEventListener("click", (e) => {
      e.preventDefault();
      const elem = document.getElementById(btn.getAttribute("data-open"));
      elem.classList.add("is-active");
    });
  });

  [].forEach.call(closeBtns, (btn) => {
    btn.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopPropagation();
      const elem = document.getElementById(btn.getAttribute("data-close"));
      elem.classList.remove("is-active");
    });
  });
});

document.addEventListener("turbolinks:before-cache", () => {
  const menu = document.querySelector("#mobile-menu");

  if (menu) {
    if (menu.classList.contains("is-active")) {
      menu.classList.remove("is-active");
    }
  }
});
