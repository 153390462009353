document.addEventListener("turbolinks:load", () => {
  const tengePrice = document.getElementById("ton-tenge-price");
  const tonWallet = document.getElementById("ton-wallet");
  const rate = document.getElementById("ton-tenge-rate");
  const comment = document.getElementById("ton-comment");

  if (tengePrice) {
    tengePrice.addEventListener("keyup", function() {
      generateLink(tengePrice.value, tonWallet.value, rate.value, comment.value);
    });

    generateLink(tengePrice.value, tonWallet.value, rate.value, comment.value);
    }
});

// Generate link for TonKeeper
function generateLink(tengePrice, tonWallet, rate, comment) {
  const paymentLink = document.getElementById("ton-invoice-button");
  const amountToPay = Math.round(tengePrice / rate * 10000000) / 10000000;
  const amountToPayInFraction = amountToPay * 1000000000;
  const tonAmountDisplay = document.getElementById("ton-amount-display");

  const href = `ton://transfer/${tonWallet}?amount=${amountToPayInFraction}&text=${comment}`;

  paymentLink.href = href;
  tonAmountDisplay.innerHTML = amountToPay;
}
