import React from "react";

import { SortableContainer } from "react-sortable-hoc";

import NoPhotos from "./NoPhotos";
import Photo from "./Photo";

const Photos = SortableContainer(({ handlePhotoDelete, photos, sortMode, noPhotosText, noPhotosImage, onNoPhotosImageClick }) => (
  <div className={`photomanager-photos ${sortMode ? "sort-mode" : ""}`}>
    {
      photos.length > 0
        ? photos.map((photo, idx) => <Photo {...photo} handlePhotoDelete={handlePhotoDelete} sortMode={sortMode} disabled={!sortMode} index={idx} position={idx} key={idx} />)
        : <NoPhotos text={noPhotosText} imageSrc={noPhotosImage} onImageClick={onNoPhotosImageClick} />
    }
  </div>
));

export default Photos;
