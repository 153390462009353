document.addEventListener("turbolinks:load", () => {
  const copyButtons = document.querySelectorAll("[data-copy-to-clipboard]");
  [].forEach.call(copyButtons, (button) => {
    button.addEventListener("click", (e) => {
      e.preventDefault();
      // Get data from data-copy-to-clipboard
      const data = button.getAttribute("data-copy-to-clipboard");
      // Copy to clipboard
      navigator.clipboard.writeText(data);
      // Change button text to "Скопировано"
      const originalText = button.innerText;
      button.innerText = "Скопировано";
      // Change button text back to original after 1 seconds
      setTimeout(() => {
        button.innerText = originalText;
      }, 1000);
    });
  });
});
