document.addEventListener("turbolinks:load", () => {
  const currency = document.getElementById("qw-currency");
  if (currency) {
    const rubRate = document.getElementById("qw-rub-rate");
    const rubles = document.getElementById("qw-rubles");
    const rublesHidden = document.getElementById("qw-rubles-hidden");

    const amountToPay = Math.round(currency.value / rubRate.value * 100) / 100;

    rubles.value = "Оплатить " + amountToPay + " руб.";
    rublesHidden.value = amountToPay;

    currency.addEventListener("keyup", function() {
      const amountToPay = Math.round(currency.value / rubRate.value * 100) / 100;

      rubles.value = "Оплатить " + amountToPay + " руб.";
      rublesHidden.value = amountToPay;
    });
  }
});
