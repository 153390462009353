Date.prototype.addMinutes = function(minutes) {
  this.setMinutes(this.getMinutes() + minutes);
  return this;
};

document.addEventListener("turbolinks:load", () => {
  const timepicker = document.querySelector("#timepicker");

  const defaultTime = (stringHour, stringMinutes) => {
    let currentMinutes = parseInt(stringMinutes);
    let currentHour = parseInt(stringHour);
    let tenMinutesAdded = Math.round((currentMinutes + 10) / 5) * 5;

    currentMinutes >= 50 ? currentHour += 1 : currentHour
    tenMinutesAdded >= 60 ? tenMinutesAdded %= 10 : tenMinutesAdded
    return { currentHours: currentHour, currentMinutes: tenMinutesAdded }
  }

  if (timepicker) {
    let { currentHours, currentMinutes } = defaultTime(timepicker.getAttribute("data-current-hour"), timepicker.getAttribute("data-current-min"))
    // Buttons
    const hoursUpBtn = timepicker.querySelector("#timepicker-hours-up");
    const hoursDownBtn = timepicker.querySelector("#timepicker-hours-down");
    const minutesUpBtn = timepicker.querySelector("#timepicker-minutes-up");
    const minutesDownBtn = timepicker.querySelector("#timepicker-minutes-down");

    // Display
    const hoursDisplay = timepicker.querySelector("#timepicker-display-hours");
    const minutesDisplay = timepicker.querySelector("#timepicker-display-minutes");

    // Time input
    const timeInput = document.querySelector("#selected_time")

    const zeroPad = (num, places) => String(num).padStart(places, "0");

    const updateTime = () => {
      timeInput.value = `${zeroPad(currentHours, 2)}:${zeroPad(currentMinutes, 2)}`
      hoursDisplay.textContent = zeroPad(currentHours, 2);
      minutesDisplay.textContent = zeroPad(currentMinutes, 2);
    }

    const increaseHours = () => {
      currentHours = Math.min(currentHours + 1, 23);
      updateTime();
    }

    const decreaseHours = () => {
      currentHours = Math.max(currentHours - 1, 0);
      updateTime();
    }

    const increaseMinutes = () => {
      currentMinutes = Math.min(currentMinutes + 5, 55);
      updateTime();
    }

    const decreaseMinutes = () => {
      currentMinutes = Math.max(currentMinutes - 5, 0);
      updateTime();
    }


    updateTime();

    hoursUpBtn.addEventListener("click", () => increaseHours());
    hoursDownBtn.addEventListener("click", () => decreaseHours());
    minutesUpBtn.addEventListener("click", () => increaseMinutes());
    minutesDownBtn.addEventListener("click", () => decreaseMinutes());
  }
});
