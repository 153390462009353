document.addEventListener("turbolinks:load", () => {
  const resetNotifications = document.querySelector("#reset-notifications");

  if (resetNotifications) {
    document.querySelector("#feedbacks-count-badge").remove();

    setTimeout(() => {
      const badge = document.querySelector("#notifications-badge");
      badge.classList.add("animate__animated");
      badge.classList.add("animate__bounceOut");
    }, 1000);
  }
});
