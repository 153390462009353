function getTimeRemaining(endtime) {
  const t = Date.parse(endtime) - Date.parse(new Date().toISOString());
  const seconds = Math.floor((t / 1000) % 60);
  const minutes = Math.floor((t / 1000 / 60) % 60);
  const hours = Math.floor((t / (1000 * 60 * 60)));

  return {
    'total': t,
    'hours': hours,
    'minutes': minutes,
    'seconds': seconds
  };
};

function initializeClock(endtime) {
  const hoursSpan = document.getElementById('hours');
  const minutesSpan = document.getElementById('minutes');
  const secondsSpan = document.getElementById('seconds');

  function updateClock() {
    const t = getTimeRemaining(endtime);

    hoursSpan.innerHTML = ('0' + `${t.hours}`).slice(-2);
    minutesSpan.innerHTML = ('0' + `${t.minutes}`).slice(-2);
    secondsSpan.innerHTML = ('0' + `${t.seconds}`).slice(-2);

    if (t.total <= 0) {
      clearInterval(timeinterval);
    }
  }

  updateClock();
  const timeinterval = setInterval(updateClock, 1000);
}

document.addEventListener("turbolinks:load", () => {
  if (document.getElementById("countdown")) {
    const deadline = document.getElementById("countdown").getAttribute("data-deadline");
    initializeClock(deadline);
  }

  const timeoutButtons = document.querySelectorAll("[data-activate-after]");
  
  Array.from(timeoutButtons).forEach(button => {
    button.disabled = true;
    button.classList.add("is-disabled");
    const initialText = button.innerText;
    button.innerHTML = `Можно закрыть через ${button.dataset.activateAfter} с.`;

    let i = 1;

    const count = setInterval(() => {
      button.innerHTML = `Можно закрыть через ${button.dataset.activateAfter - i} с.`;
      i++;
    }, 1000);

    setTimeout(() => {
      clearInterval(count);
      button.disabled = false;
      button.classList.remove("is-disabled");
      button.innerHTML = initialText;
    }, button.dataset.activateAfter * 1000);
  });
})
