import React from "react";
import PropTypes from "prop-types";

const NoPhotos = ({ text, imageSrc, onImageClick }) => (
  <div className="text-center full-width">
    <img
      src={imageSrc}
      alt="No photos"
      className="photomanager__no-photos-image"
      onClick={onImageClick}
    />
    <p className="photomanager__no-photos-text">
      {text}
    </p>
  </div>
);

NoPhotos.propTypes = {
  text: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
};

export default NoPhotos;

