document.addEventListener("turbolinks:load", () => {
  const highlightable = document.querySelector("#highlightable");

  if (highlightable) {
    const urlParam = new URLSearchParams(window.location.search).get("select");
    
    if (urlParam) {
      const positions = urlParam.split(",").map(elem => parseInt(elem, 10));
      
      const elems = highlightable.querySelectorAll("li");
      [].forEach.call(elems, (elem, idx) => {
        if (positions.includes(idx + 1)) {
          elem.classList.add("highlighted");
        }
      });
    }
  };
});
