import React from "react";
import Swiper from "react-id-swiper";
import baguetteBox from "baguettebox.js";

import Photo from "./Photo";

class Slider extends React.Component {
  componentDidMount() {
    baguetteBox.run(".swiper-wrapper");
  }

  render() {
    const params = {
      pagination: {
        el: ".swiper",
        clickable: true
      }
    }

    const { photos } = this.props;

    return (
      <Swiper {...params} shouldSwiperUpdate={true} >
        { photos.map(photo => <a href={photo.image.url} key={photo.id}><Photo photo={photo} /></a>) }
      </Swiper>
    )
  }
}

export default Slider;
